<template>
 <p>hallo</p>
</template>

<script>

  export default {
    name: 'Home',

    components: {
      
    },
  }
</script>
